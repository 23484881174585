.contact {
    width: 100vw;
    /* height: 100vh; */
    background-image: url('../Assets/images/fruits/PNG/fruta-pequeña.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom 0px center;
}
.contact-container {
    width: 100vw;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    padding: 15vh 0;
    overflow-y: scroll;
} 

.Email, .WhatsApp, .Instagram {
    width: 90vw;
    max-width: 400px;
    height: 30vh;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    border-radius: 25px;
    background-color: var(--fucksia-soft);
    opacity: 0;
}
.Email > img, 
.WhatsApp > img, 
.Instagram > img {
    width: 50px;
}

.Email > h1, .WhatsApp > h1, .Instagram > h1,
.Email > a, .WhatsApp > a, .Instagram > a {
    font-size: 2.4rem;
    color: var(--white);
}

.Email > h1, 
.WhatsApp > h1, 
.Instagram > h1 {
    font-weight: bold;
}
.Email > a, 
.WhatsApp > a, 
.Instagram > a {
    cursor: none;
    font-weight: 300;
    text-decoration: none;
    outline: none;
}
@media (min-width: 500px) {
    .contact {
        background-image: url('../Assets/images/fruits/PNG/fruta-mediana.png');
    }
}
@media (min-width: 900px) {
    .contact {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }
    .contact-container {
        display: grid;
        max-width: 1000px;
        grid-template: repeat(2, 1fr) /repeat(2, 1fr) ;
        grid-template-areas: 
        "email whatsapp"
        "instagram instagram";
        place-content: center;         
        row-gap: 50px;
        padding: 0;
        overflow-y: visible;
    }
    .Email {
        grid-area: email;
        align-self: end;
        justify-self: center;
    }
    .WhatsApp {
        grid-area: whatsapp;
        align-self: end;
        justify-self: center;
    }
    .Instagram {
        grid-area: instagram;
        align-self: start;
        justify-self: center;
    }
}
@media (min-width: 1024px) {
    .Email > a, 
    .WhatsApp > a, 
    .Instagram > a {
        cursor: pointer;
    }
    .Email > a:hover, 
    .WhatsApp > a:hover, 
    .Instagram > a:hover {
        /* font-weight: bold; */
        opacity: 80%;
    }
}
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
}
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
}
.fade-in-bottom-1 {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}
.fade-in-bottom-2 {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
}
.fade-in-bottom-3 {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.5s both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.5s both;
}