@keyframes animloader {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
}
    
@-webkit-keyframes heartbeat {
        from {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: center center;
                  transform-origin: center center;
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        10% {
          -webkit-transform: scale(0.91);
                  transform: scale(0.91);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        17% {
          -webkit-transform: scale(0.98);
                  transform: scale(0.98);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        33% {
          -webkit-transform: scale(0.87);
                  transform: scale(0.87);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        45% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
}
@keyframes heartbeat {
        from {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: center center;
                  transform-origin: center center;
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        10% {
          -webkit-transform: scale(0.91);
                  transform: scale(0.91);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        17% {
          -webkit-transform: scale(0.98);
                  transform: scale(0.98);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        33% {
          -webkit-transform: scale(0.87);
                  transform: scale(0.87);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        45% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
}

@-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
@keyframes fade-out {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}
.noClick{
    pointer-events: none;
}
.loader-container {
    display: none; /* Cambiar */
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -4;
    column-gap: 10px;
    background-color: var(--fucksia);
    -webkit-animation: fade-out 5s ease-in-out both;
    animation: fade-out 5s ease-in-out both;
}
.loaderOn {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}
.loader {
    font-size: 40px;
    display: inline-block;
    font-weight: bold;
    color: var(--fucksia);
    box-sizing: border-box;
    text-shadow: 0 0 2px #FFF, 0 0 1px #FFF, 0 0 1px #FFF;
    letter-spacing: 2px;
    position: relative;
    }
.loader::after {
    content: 'Kimnhealth';
    position: absolute;
    left: 0;
    top: 0;
    color: #FFF;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: animloader 4s linear;
}
.loader-container > img {
    width: 50px;
    max-width: 100px;
    animation: heartbeat 4s linear;
}

@media(min-width: 600px) {
        .loader {
            font-size: 70px;
        }
        .loader-container > img {
            width: 80px;
        }
}
@media(min-width: 1024px) {
    .loader {
        font-size: 90px;
    }
    .loader-container > img {
        width: 100px;
    }
}