:root {
    /* Colors */
    --fucksia: #ff2180;
    --fucksia-soft: #ff4094;
    --pink: #fbdbe0;
    --green-caine: #a5d548;
    --white: white;
    /* Font */
    --font-title: 'Playfair Display', serif;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    font-family: 'Lato', sans-serif;
}

@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
.fade-in-logo {
	-webkit-animation: fade-in 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.2s both;
	animation: fade-in 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.2s both;
}  
.fade-in {
	-webkit-animation: fade-in 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	animation: fade-in 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}  
.fade-in-bottom {
	-webkit-animation: fade-in-bottom 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
.fade-in-left {
	-webkit-animation: fade-in-left 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
.fade-in-right {
	-webkit-animation: fade-in-right 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-right 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}  