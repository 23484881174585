.home {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    opacity:0;
} 
.home-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 20px;
}
.logo {
    grid-row: 1 / 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.logo img {
    width: 180px;
}
/* .home-bg {
    grid-row: 3 / 4;
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('../Assets/icons/veggies-bg-small.png');
    background-size: 122%;
    background-attachment: fixed;
    background-position: bottom 0px center;
    background-repeat: no-repeat;
} */
.home {
    background-image: url('../Assets/icons/veggies-bg-small.png');
    background-size: 122%;
    background-attachment: fixed;
    background-position: bottom 0px center;
    background-repeat: no-repeat;
}

/* =================== */
.title {
    grid-row: 2 / 3;
    min-width: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.name {
    font-family: var(--font-title);
    font-size: 2.8rem;
}
.profession {
    font-size: 2.2rem;
    font-weight: 300;
}
.name, .profession {
    color: var(--fucksia);
}
@media (min-width: 500px) {
    .name {
        font-size: 3.2rem;
    }
    .profession {
        font-size: 2.6rem;
    }
    .home{
        background-position: bottom -100px center;
    }
}
@media (min-width: 900px) {
    .logo img {
        width: 240px;
    }
    .name {
        font-size: 3.8rem;
    }
    .profession {
        font-size: 3.2rem;
    }
    .home{
        background-position: bottom -200px center;
    }
}
@media (min-width: 1280px) {
    .logo img {
        width: 240px;
    }
    .name {
        font-family: var(--font-title);
        font-size: 3.8rem;
    }
    .profession {
        font-size: 3.2rem;
        font-weight: 300;
    }
    .home{
        background-image: url('../Assets/icons/veggies-bg-bigger.png');
        background-position: bottom -100px center;
    } 
}

/* ======= Animaciones ====== */
/* 
* ----------------------------------------
* animation fade-in-fwd
* ---------------------------------------- 
*/
@-webkit-keyframes fade-in-fwd {
 0% {
   -webkit-transform: translateZ(-80px);
           transform: translateZ(-80px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
   opacity: 1;
 }
}
@keyframes fade-in-fwd {
 0% {
   -webkit-transform: translateZ(-80px);
           transform: translateZ(-80px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
   opacity: 1;
 }
}
.fade-in-fwd {
	-webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.2s both;
	animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.2s both;
}

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bottom-home {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom-home {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
.fade-in-bottom-home {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.5s both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.5s both;
}