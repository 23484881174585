/* ================ ANIMACIONES ================= */
/* Entrada */
@-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
/* Salida */
@-webkit-keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
  }
  @keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
  }
  
/* =============================================== */
.headerChange {
  background-color: var(--fucksia);
}
.menu-btnOpen, .menu-btnClose > img {
    width: 25px;
}
.menu-btnOpen {
    margin-right: 20px;
}
.menu-btnClose {
    position: absolute;
    top: 20px;
    right: 20px;
}
.btnChange {
  filter: brightness(0) saturate(100%) invert(81%) 
  sepia(99%) saturate(0%) hue-rotate(20deg) 
  brightness(110%) contrast(100%);
}
.nav-bar {
    position: fixed;
    list-style: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -3;
    -webkit-animation: slide-out-left 0.9s linear both;
	  animation: slide-out-left 0.9s linear both;
    overflow-y: none;
    background-color: var(--green-caine);
}
.nav-bar__active {
    position: fixed;
    z-index: 10;
    -webkit-animation: slide-in-left 0.9s ease both;
	  animation: slide-in-left 0.59s ease both;
}
.nav-bar .texture-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    opacity: 20%;
}
.nav-bar .texture-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    opacity: 20%;
}
.texture-left, .texture-right {
  z-index: -1;
}
.logoWithBg {
    width: 90px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.sections-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 70px;
    position: relative;
    z-index: 11;
    /* background-color: black; */
}
.sections-list li {
    display: flex;
    justify-content: center;
    width: 100%;
    outline: none;
}
.sections-list li a {
  cursor: none;
  text-decoration: none;
  font-size: 2.8rem;
  font-weight: 900;
  color: var(--white);
}

@media (min-width: 1024px) {
    /* header {
        background-color: var(--green-caine);
    } */
    .menu-btnOpen, 
    .menu-btnClose,
    .nav-bar .texture-left,
    .nav-bar .texture-right,
    .logoWithBg {
        display: none;
    }
    .nav-bar {
        position: relative;
        height: auto;
        flex-direction: row;
        -webkit-animation: none;
        animation: none;
        background-color: transparent;
    }
    .sections-list {
        flex-direction: row;
        justify-content: right;
        gap: 5vw;
        margin-right: 40px;
    }
    .sections-list  li {
        width: auto;
        justify-content: space-evenly;
    }
}

