.services {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../Assets/images/fruits/PNG/Kiwi-pequeña.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}
.service-card {
    position: relative;
    width: 90vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--green-caine);
    padding: 5%;
    border-radius: 20px;
    row-gap: 10px;
    opacity: 0;
    transition: 1s;
}
.card-loaded {
    opacity: 1;
}
.service-title, .service-description {
    align-self: flex-start;
    font-style: oblique;
    color: var(--white);
}
.service-title {
    font-size: 2rem;
    font-weight: 900;
}
.service-description {
    font-size: 1.8rem;
}
.service-card > img {
    position: absolute;
    bottom: 10px;
    align-self: flex-end;
    width: 20vw; /* 20vw 150px*/
    max-width: 150px;
}
.service-card__buttons{
    width: 90vw;
    max-width: 900px;
    display: flex;
    justify-content: space-around;
    /* gap: 50px; */
}
.card-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 2rem;
    font-weight: bold;
    color: var(--white);
    background-color: var(--green-caine);
}
@media (min-width: 600px) {
    .service-title {
        font-size: 2.8rem;
        font-weight: 900;
    }
    .service-description {
        font-size: 2.6rem;
    }
}
@media (min-width: 1024px) {
    .service-title {
        font-size: 3.2rem;
        font-weight: 900;
    }
    .service-description {
        font-size: 3rem;
    }
}
@media(min-width: 1280px) {
    .service-card {
        width: 900px;
        height: 500px;
    }
}