.about {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    overflow-x: hidden;
}
.biography, .vision, .mision {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    row-gap: 20px;
    /* padding: 0 10%; */
}
.biography-title, 
.vision-title, 
.mision-title {
    width: 100vw;
    max-width: 900px;
    font-family: var(--font-title);
    font-size: 2.8rem;
    font-weight: bold;
    padding: 0 10%;
    opacity: 0;
}
.biography-description, 
.vision-description, 
.mision-description {
    width: 100vw;
    max-width: 900px;
    font-size: 2.2rem;
    padding: 0 10%;
    opacity: 0;
}
.biography {
    grid-row: 1 / 2;
    border-radius: 0% 0% 50% 50% / 15% 15% 5% 5%;
    background-color: var(--pink);
    background-image: url('../Assets/images/fruits/PNG/Toronja.png');
    background-repeat: no-repeat;
    background-position: top right -300px;
    background-size: cover;
}
.biography-title, .biography-description {
    align-self: flex-start;
    text-align: left;
}
.vision {
    position: relative;
    overflow: hidden;
    grid-row: 2 / 3;
    background-image: url('../Assets/images/fruits/PNG/fresas-pequeña.png');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 100vh;
}
.vision-title, .vision-description {
    align-self: flex-end;
    position: relative;
    z-index: 1;
    text-align: right;
}
.brush-line, .brush-circle {
    position: absolute;
    z-index: 0;
}
.brush-line {
    width: 300px;
    top: 40px;
    left: -100px;
}
.brush-circle {
    width: 150px;
    bottom: 0;
    right: -50px;
}
.mision {
    grid-row: 3 / 4;
    border-radius: 50% 50% 0% 0% / 5% 5% 15% 15%;
    background-color: var(--pink);
    background-image: url('../Assets/images/fruits/PNG/Aguacate.png');
    background-repeat: no-repeat;
    background-position: bottom right -200px;
    background-size: cover;
}
.mision-title, .mision-description {
    align-self: flex-start;
    text-align: left;
}
@media (min-width: 400px) {
    .biography-title, 
    .vision-title, 
    .mision-title {
        font-size: 3.6rem;
        /* padding: 0 5%; */
    }
    .biography-description, 
    .vision-description, 
    .mision-description {
        font-size: 2.6rem;
        /* padding: 0 5%; */
    }
}
@media (min-width: 900px) {
    .brush-line {
        width: 400px;
        left: -160px;
    }
    .brush-circle {
        width: 250px;
        right: -70px;
    }
    .biography-title, 
    .vision-title, 
    .mision-title {
        font-size: 4rem;
        max-width: 1080px;
        /* padding: 0 5%; */
    }
    .biography-description, 
    .vision-description, 
    .mision-description {
        font-size: 3rem;
        max-width: 1080px;
        /* padding: 0 5%; */
    }
}
@media(min-width: 1024px) {
    .vision {
        background-size: 110vw;
    }
}
@media (min-width: 1280px) {
    .brush-line {
        width: 500px;
        left: -160px;
    }
    .brush-circle {
        width: 300px;
    }
    .biography {
        background-size: 120vw;
    }
    .vision {
        background-position: center;
        background-size: 200vh;
    }
    .mision {
        background-size: 120vw;
    }
}